require('./bootstrap');
import 'jquery-ui/ui/widgets/autocomplete.js';
import 'jquery-ui/ui/widgets/datepicker.js';
require('./main');

const feather = require('feather-icons');
feather.replace();

jQuery.fn.bShow = function() {
    var o = $(this)
    o.removeClass('d-none');

    return this;
};

jQuery.fn.bHide = function() {
    var o = $(this)
    o.addClass('d-none');

    return this;
};

jQuery.fn.bToggle = function() {
    var o = $(this)
    if (o.hasClass('d-none'))
    {
        o.bShow();
    }
    else
    {
        o.bHide();
    }

    return this;
};

jQuery.fn.selectText = function(){
    var doc = document;
    var element = this[0];
    if (doc.body.createTextRange) {
        var range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
    } else if (window.getSelection) {
        var selection = window.getSelection();        
        var range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
    }
 };
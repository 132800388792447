$('tr[data-href], .row[data-href]').on('click', function() {
    die = false;
    $.each(event.path, function(a, element) {
        if ($(element).is('a'))
        {
            die = true;
            return false;
        }
    });
    if ($(this).data('href') != '' && die == false)
    {
        window.location = $(this).data('href');
    }
});


$('.datepicker').each(function()
{
    options = {dateFormat:'dd-mm-yy'};
    $.each($(this).data(), function(option, value)
    {
        options[option] = value;
    });
    $(this).datepicker(options);
});

$(function () {
    $('[data-toggle="popover"]').popover({
     });

})

$(function () {
    $('[data-toggle="tooltip"]').tooltip({});
})